import React, { useContext } from 'react';
import {
    Box,
    useTheme,
    IconButton,
    Button,
    useMediaQuery,
    AppBar,
    Toolbar,
    Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ColorModeContext } from '../theme'; // Adjust the import path as needed

function Header() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="fixed" sx={{
            top: -8, // Moves the AppBar up by 8 pixels
            backgroundColor: 'rgba(0,0,0,0)', // Transparent background
            backdropFilter: 'blur(1px)', // Apply blur effect
            boxShadow: 'none', // Remove default shadow
            // Ensure the AppBar stretches fully if needed, with no inherent rounded borders
        }}>
            <Container maxWidth="lg" sx={{
                marginTop: '16px', // Add top margin to push content down slightly
                borderRadius: '20px', // Slightly rounded borders for the Container
                overflow: 'hidden', // Ensures that children of the container do not bleed outside the rounded corners
            }}>
                <Toolbar disableGutters sx={{
                    justifyContent: 'space-between',
                    padding: theme.spacing(2),
                }}>
                    <img src="/logo-no-background.png" alt="Company Logo" style={{ height: '50px' }} />
                    {!isMobile && (
                        <Box>
                            <IconButton onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    m: "10px 10px 10px 10px",
                                }}
                                onClick={() => window.location.href = '/app'}
                            >
                                Track Portfolio
                            </Button>
                        </Box>
                    )}
                    {isMobile && (
                        <IconButton color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;

import React from 'react';
import {
    Typography,
    Box,
    useTheme,
    Stack,
    Link,
    Container,
    useMediaQuery,
  } from '@mui/material';

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const footerStyle = {
    position: 'relative', // Position relative to place it at the bottom
    width: '100%',
    color: theme.palette.text.primary,
    padding: theme.spacing(4),
    //display: 'flex',
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0)', // Transparent background
    backdropFilter: 'blur(10px)', // Apply blur effect
    boxShadow: 'none',
    borderRadius: '20px',
  };

  const footerInnerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: 'lg',
    width: '100%', // Ensure it fills the Box, respecting padding
    borderRadius: '20px', // Apply borderRadius to match the outer Box
    overflow: 'hidden', // Ensures that children respect the border radius

  };
  return (
    <Box component="footer" sx={footerStyle}>
        <Container sx={footerInnerStyle}>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h5">Portfolio Manager</Typography>
            <Link href="#" variant="body2">Performance Analytics</Link>
            <Link href="#" variant="body2">Risk Analytics</Link>
            <Link href="#" variant="body2">Portfolio Optimizer</Link>
          </Stack>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h5">Company</Typography>
            <Link href="#" variant="body2">About Us</Link>
            <Link href="#" variant="body2">Product Updates</Link>
            <Link href="#" variant="body2">Product Updates</Link>
          </Stack>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h5">Legal</Typography>
            <Link href="#" variant="body2">Disclaimer</Link>
            <Link href="#" variant="body2">Privacy Policy</Link>
            <Link href="#" variant="body2">Terms of Service</Link>
          </Stack>
        </Container>
      </Box>
  );
}

export default Footer;

import React from "react";
import { Route, Routes} from "react-router-dom";
import Landing from "../scenes/Landing";

export const AppRoutes = () => {

    return (
        <Routes>
            {/* Main Layout Routes */}
            <Route index element={<Landing />} />

            {/* Fallback Route */}
            <Route path="*" element={<Landing />} />
        </Routes>
    );
};

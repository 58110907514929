import { createContext, useState, useMemo } from "react";
import { alpha, createTheme } from "@mui/material/styles";

const contrastText = '#fff';

let errorColorsLight = ['#FDE8E7', '#F25E52', '#F04134', '#EE3B2F', '#E92A21'];
let warningColorsLight = ['#FFF7E0', '#FFC926', '#FFBF00', '#FFB900', '#FFA900'];
let infoColorsLight = ['#E0F4F5', '#26B0BA', '#00A2AE', '#009AA7', '#008694'];
let successColorsLight = ['#E0F5EA', '#26B56E', '#00A854', '#00A04D', '#008D3A'];

let errorColorsDark = ['#321d1d', '#7d2e28', '#d13c31', '#e66859', '#f8baaf'];
let warningColorsDark = ['#342c1a', '#836611', '#dda705', '#e9bf28', '#f8e577'];
let infoColorsDark = ['#1a2628', '#11595f', '#058e98', '#1ea6aa', '#64cfcb'];
let successColorsDark = ['#1a2721', '#115c36', '#05934c', '#1da65d', '#61ca8b'];


export const tokensDark = {
  grey: {
    0: "#FFFFFF", // manually adjusted
    5: "#FFF5F5",
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  primary: {
    // blue
    100: "#d3d4de",
    200: "#a6a9be",
    300: "#7a7f9d",
    400: "#4d547d",
    500: "#21295c",
    600: "#191F45", // manually adjusted
    700: "#141937",
    800: "#0d1025",
    900: "#070812",
  },
  secondary: {
    // yellow
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#ffe3a3",
    400: "#ffda85",
    500: "#ffd166",
    600: "#cca752",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
  },
};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode) => {
  const errorColors = mode === "dark" ? errorColorsDark : errorColorsLight;
  const warningColors = mode === "dark" ? warningColorsDark : warningColorsLight;
  const infoColors = mode === "dark" ? infoColorsDark : infoColorsLight;
  const successColors = mode === "dark" ? successColorsDark : successColorsLight;

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            ...tokensDark.primary,
            main: tokensDark.primary[400],
            light: tokensDark.primary[600],
          },
          secondary: {
            ...tokensDark.secondary,
            main: tokensDark.secondary[300],
          },
          neutral: {
            ...tokensDark.grey,
            main: tokensDark.grey[500],
          },
          background: {
            default: tokensDark.primary[600],
            alt: tokensDark.primary[500],
          },
          divider: tokensDark.grey[800],
          border: tokensDark.grey[200],
          action: {
            disabled: tokensDark.grey[300]
          },
          text: {
            primary: alpha(tokensDark.grey[100], 0.87),
            secondary: alpha(tokensDark.grey[100], 0.45),
            disabled: alpha(tokensDark.grey[100], 0.1),
          },
        }
        : {
          // palette values for light mode
          primary: {
            ...tokensLight.primary,
            main: tokensDark.grey[50],
            light: tokensDark.grey[100],
            contrastText
          },
          secondary: {
            ...tokensLight.secondary,
            main: tokensDark.secondary[600],
            light: tokensDark.secondary[700],
            contrastText: tokensDark.grey[100]
          },
          neutral: {
            ...tokensLight.grey,
            main: tokensDark.grey[500],
          },
          background: {
            default: tokensDark.grey[50],
            alt: tokensDark.grey[0],
          },
          divider: tokensDark.grey[200],
          border: tokensDark.grey[400],
          action: {
            disabled: tokensDark.grey[300]
          },
          text: {
            primary: tokensDark.grey[700],
            secondary: tokensDark.grey[500],
            disabled: tokensDark.grey[400],
          },
        }),
      error: {
        lighter: errorColors[0],
        light: errorColors[1],
        main: errorColors[2],
        dark: errorColors[3],
        darker: errorColors[4],
        contrastText
      },
      warning: {
        lighter: warningColors[0],
        light: warningColors[1],
        main: warningColors[2],
        dark: warningColors[3],
        darker: warningColors[4],
        contrastText: tokensDark.grey[100]
      },
      info: {
        lighter: infoColors[0],
        light: infoColors[1],
        main: infoColors[2],
        dark: infoColors[3],
        darker: infoColors[4],
        contrastText
      },
      success: {
        lighter: successColors[0],
        light: successColors[1],
        main: successColors[2],
        dark: successColors[3],
        darker: successColors[4],
        contrastText
      },
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    shadows: {
        customLight: '0 4px 8px rgba(0, 0, 0, 0.1)',
        customDark: '0 8px 16px rgba(0, 0, 0, 0.2)',
      }
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
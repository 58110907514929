import React from 'react';
import { Box, useTheme } from '@mui/material';
import Header from '../components/Header';
import Body from '../components/Body';
import Footer from '../components/Footer';

const Landing = () => {
  const theme = useTheme();
  const gradientBackground = {
    backgroundImage: `url('/landing-background.webp')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8),
  };
  return (
    // Apply a flex layout with column direction and full viewport height
    <Box 
      sx={gradientBackground}
    >
      <Header />
      {/* flex: 1 will allow the Body to expand and fill available space, pushing the Footer to the bottom */}
      <Box flex={1}>
        <Body />
      </Box>
      <Footer />
    </Box>
  );
};

export default Landing;

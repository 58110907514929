import React from 'react';
import {   
    Typography,
    Box,
    IconButton,
    Stack
} from '@mui/material';

import { LinkedIn } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';

function Body() {

  const centeredContentStyle = {
    position: 'absolute', // Absolute position for the content
    top: '30%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust the exact centering
    textAlign: 'center', // Center align the text
    zIndex: 2, // Ensure it's above the background
    backgroundColor: 'rgba(0,0,0,0)', // Transparent background
    backdropFilter: 'blur(3px)'
};
  return (
    <Box sx={centeredContentStyle}>
    <Typography variant="h2" component="h1" gutterBottom>
      Prepare for a New Era in Crypto Portfolio Management
    </Typography>
    <Stack direction="row" spacing={2} justifyContent="center">
      <IconButton aria-label="X" color="secondary" onClick={() => window.location.href = 'https://x.com/coinsheetio'}>
        <XIcon />
      </IconButton>
      <IconButton aria-label="LinkedIn" color="secondary" onClick={() => window.location.href = 'https://www.linkedin.com/company/coinsheet-io'}>
        <LinkedIn />
      </IconButton>
    </Stack>
  </Box>
  );
}

export default Body;
